import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import RentalSummary from "./RentalSummary";
import api from "../../../service/race_api";
import {
  FilterContext,
  AdditionalChargesContext,
} from "../../../components/context/MainProviders.js";

const AdditionalCharges = (props) => {
  const filter = useContext(FilterContext);
  const additionalCharges = useContext(AdditionalChargesContext);
  const [listAdditionaCharges, setListAdditionalCharges] = useState([]);
  const [additionaChargesError, setAdditionalChargesError] = useState(false);
  const [additionaChargesErrorMessage, setAdditionalChargesErrorMessage] =
    useState("");
  const [listSelectedAdditionalCharges, setListSelectedAdditionalCharges] =
    useState(
      sessionStorage.getItem("additional_charges") !== null
        ? JSON.parse(sessionStorage.getItem("additional_charges")).selectedItems
        : []
    );
  const selectService = (
    value,
    id_service,
    amount,
    id_category,
    category_name,
    name,
    icon,
    selection_type,
    charge_type
  ) => {
    let id = "service-charge" + "-" + id_service;
    let service_charge = document.getElementById(id);
    if (value > 0 || value === true) {
      service_charge.classList.remove("bg-green-100");
      service_charge.classList.remove("border-green-200");
      service_charge.classList.remove("text-green-700");
      service_charge.classList.add("bg-neutral-100");
      service_charge.classList.add("border-neutral-200");
      service_charge.classList.add("text-neutral-700");
    } else {
      service_charge.classList.add("bg-green-100");
      service_charge.classList.add("border-green-200");
      service_charge.classList.add("text-green-700");
      service_charge.classList.remove("bg-neutral-100");
      service_charge.classList.remove("border-neutral-200");
      service_charge.classList.remove("text-neutral-700");
    }
    //update additiona sevices with the selected service
    updateAdditionalCharges(
      value,
      id_service,
      amount,
      id_category,
      category_name,
      name,
      icon,
      selection_type,
      charge_type
    );
  };
  const updateAdditionalCharges = (
    value,
    id_service,
    amount,
    id_category,
    category_name,
    name,
    icon,
    selection_type,
    charge_type
  ) => {
    let found_category = false;
    let found_service = false;
    let index_category = 0;
    let tmpSelectedAdditionalCharges = JSON.parse(
      sessionStorage.getItem("additional_charges")
    ).selectedItems;
    for (let index = 0; index < tmpSelectedAdditionalCharges.length; index++) {
      //find the category
      if (tmpSelectedAdditionalCharges[index].id_category === id_category)
        found_category = true;

      //find the service
      if (found_category) {
        for (
          let index2 = 0;
          index2 < tmpSelectedAdditionalCharges[index].list.length;
          index2++
        ) {
          if (
            tmpSelectedAdditionalCharges[index].list[index2].id_service ===
            id_service
          ) {
            //delete selected item
            tmpSelectedAdditionalCharges[index].list.splice(index2, 1);

            //delete the category if the list is empty after remove the item
            if (tmpSelectedAdditionalCharges[index].list.length === 0)
              tmpSelectedAdditionalCharges.splice(index, 1);

            found_service = true;
            break;
          }
        }
        index_category = index;
        break;
      }
    }

    //if category has not been found, create new one and introduce the selected service in a list
    if (!found_category) {
      let selectedAdditionalChargeItem = {
        name: name,
        value: value,
        id_service: id_service,
        amount: amount,
        icon: icon,
        selection_type: selection_type,
        charge_type: charge_type,
      };
      let selectedAdditionalCharge = {
        id_category: id_category,
        category_name: category_name,
        list: [],
      };
      selectedAdditionalCharge.list.push(selectedAdditionalChargeItem);
      tmpSelectedAdditionalCharges.push(selectedAdditionalCharge);
    }

    if (found_category && !found_service) {
      let selectedAdditionalCharge = {
        name: name,
        value: value,
        id_service: id_service,
        amount: amount,
        icon: icon,
        selection_type: selection_type,
        charge_type: charge_type,
      };
      tmpSelectedAdditionalCharges[index_category].list.push(
        selectedAdditionalCharge
      );
    }

    //update selection
    additionalCharges.updateSelectedItems(tmpSelectedAdditionalCharges);
    setListSelectedAdditionalCharges(tmpSelectedAdditionalCharges);
  };
  const getSelectionValue = (id_service) => {
    let returnValue = "";
    let tmpSelectedAdditionalCharges = JSON.parse(
      sessionStorage.getItem("additional_charges")
    ).selectedItems;
    let found = false;

    for (let index = 0; index < tmpSelectedAdditionalCharges.length; index++) {
      for (
        let index2 = 0;
        index2 < tmpSelectedAdditionalCharges[index].list.length;
        index2++
      ) {
        if (
          tmpSelectedAdditionalCharges[index].list[index2].id_service ===
          id_service
        ) {
          returnValue = tmpSelectedAdditionalCharges[index].list[index2].value;
          found = true;
        }
        if (found) break;
      }
    }
    return returnValue;
  };
  const getAdditionalCharges = () => {
    const url =
      process.env.REACT_APP_API_CAR_RENTAL_RESERVATIONS_ADDITIONAL_CHARGES;
    const tmpFilter = JSON.parse(sessionStorage.getItem("filter"));
    const data = {
      pick_up_date: tmpFilter.datePickup.split(" ")[0],
      pick_up_time: tmpFilter.datePickup.split(" ")[1],
      pick_up_location: tmpFilter.from,
      return_date: tmpFilter.dateReturn.split(" ")[0],
      return_time: tmpFilter.dateReturn.split(" ")[1],
      return_location: tmpFilter.to,
      brand_id: tmpFilter.brandId,
      vehicle_class_id: tmpFilter.classId,
    };

    api
      .get(url, { params: data })
      .then((res) => {
        if (res.data.success === false) {
          setAdditionalChargesError(true);
          setAdditionalChargesErrorMessage(res.data.errors.error_message);
        } else {
          //reorganize additional charges by category
          let tmpAdditionalChargesList = [];
          res.data.data.additional_charges.forEach((item) => {
            let found = false;
            for (
              let index = 0;
              index < tmpAdditionalChargesList.length;
              index++
            ) {
              if (
                tmpAdditionalChargesList[index].id_category ===
                item.additional_charge_category.id
              )
                found = true;
            }
            if (!found) {
              tmpAdditionalChargesList.push({
                id_category: item.additional_charge_category.id,
                category_name: item.additional_charge_category.label.en,
                list: [
                  {
                    base_price: item.base_price,
                    charge_type: item.charge_type,
                    description: item.description,
                    icon: item.icon,
                    label: item.label,
                    selection_type: item.selection_type,
                    recommended: item.recommended,
                    total_price: item.total_price,
                    mandatory: item.mandatory,
                    id: item.id,
                  },
                ],
              });
            } else {
              //search the category and push the new item
              tmpAdditionalChargesList.forEach((category) => {
                if (
                  item.additional_charge_category.id === category.id_category
                ) {
                  category.list.push({
                    base_price: item.base_price,
                    charge_type: item.charge_type,
                    description: item.description,
                    icon: item.icon,
                    label: item.label,
                    selection_type: item.selection_type,
                    recommended: item.recommended,
                    total_price: item.total_price,
                    mandatory: item.mandatory,
                    id: item.id,
                  });
                }
              });
            }
          });
          additionalCharges.updateItems(tmpAdditionalChargesList);
          setListAdditionalCharges(tmpAdditionalChargesList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const cartContinue = () => {
    const summary = document.getElementById("rental-summary");
    summary.querySelector("div.summary-actions button.continue").click();
  };
  useEffect(() => {
    setAdditionalChargesError(false);
    //init additional charges
    if (sessionStorage.getItem("additional_charges") === null) {
      additionalCharges.init();
    }
    if (Object.keys(filter).length > 0) {
      if (
        filter.hasOwnProperty("from") &&
        filter.hasOwnProperty("to") &&
        filter.hasOwnProperty("datePickup") &&
        filter.hasOwnProperty("dateReturn")
      ) {
        if (
          filter.from !== "" &&
          filter.to !== "" &&
          filter.datePickup !== null &&
          filter.dateReturn !== null
        ) {
          getAdditionalCharges();
        }
      }
    }
  }, [filter]);
  const navigate = useNavigate();
  return (
    <div id="rental-detail" className="p-2 mt-6 flex flex-col lg:flex-row">
      <div className="w-full lg:w-2/3 p-2">
        {additionaChargesError ? (
          <p className="bg-red-200 text-red-600 rounded-lg p-4">
            {additionaChargesErrorMessage}
          </p>
        ) : listAdditionaCharges !== undefined ? (
          listAdditionaCharges.map((category, index) => (
            <div
              key={"category-" + index}
              id={"additional-charge-" + index}
              className="mb-10"
            >
              <h2 className="text-red-600 text-sm border-b border-neutral-300 pt-1 pb-4 flex">
                {category.category_name}
              </h2>
              {category.list.map((service, index2) => (
                <div
                  className="row flex flex-wrap border-b pb-10 border-neutral-200"
                  key={"addtional-charge-" + index2}
                >
                  <div className="p-2 w-full md:w-9/12">
                    <h3 className="text-normal md:text-xl mb-4 font-light flex">
                      {/* <i
                        className={service.icon + " mt-[2px] mr-2 text-red-600"}
                      ></i> */}
                      {service.label}
                      {service.recommended ? (
                        <span className="p-0 h-8 relative top-0 align-middle ml-4 bg-emerald-400 block w-32 sm:w-28 text-center rounded-lg text-white font-bold text-xs before:w-0 before:h-0 before:border-8 before:border-t-0 before:border-b-8 before:border-l-emerald-400 before:block before:border-transparent before:relative before:top-1/4 before:-left-1 before:-rotate-45">
                          Recommended
                        </span>
                      ) : null}
                      {service.mandatory ? (
                        <span className="text-xs text-neutral-500">
                          (Mandatory)
                        </span>
                      ) : null}
                    </h3>
                    {/* {service.description !== "" ? (
                      <p className="text-xs text-neutral-500 font-light mb-4">
                        {service.description}
                      </p>
                    ) : null} */}
                    {/* {service.notice !== "" ? (
                      <p className="text-xs text-red-500 font-normal">
                        {service.notice}
                      </p>
                    ) : null} */}
                  </div>
                  <div
                    id={"service-charge-" + service.id}
                    className={
                      "text-xs py-4 px-1 border rounded h-12 w-36 md:w-3/12 mt-3 font-bold " +
                      (getSelectionValue(service.id) === "" ||
                      getSelectionValue(service.id) === "0" ||
                      getSelectionValue(service.id) === false
                        ? "bg-green-100 border-green-200 text-green-700"
                        : "bg-neutral-100 border-neutral-200 text-neutral-700")
                    }
                  >
                    {service.selection_type !== "only_one" ? (
                      <select
                        className="p-1 mr-1 bg-white border border-neutral-200 rounded"
                        onChange={(e) =>
                          selectService(
                            e.target.value,
                            service.id,
                            service.base_price.amount,
                            category.id_category,
                            category.category_name,
                            service.label,
                            service.icon,
                            service.selection_type,
                            service.charge_type
                          )
                        }
                        value={
                          getSelectionValue(service.id) !== ""
                            ? getSelectionValue(service.id)
                            : "0"
                        }
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                    ) : (
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(e) =>
                          selectService(
                            e.target.checked,
                            service.id,
                            service.base_price.amount,
                            category.id_category,
                            category.category_name,
                            service.label,
                            service.icon,
                            service.selection_type,
                            service.charge_type
                          )
                        }
                        checked={
                          getSelectionValue(service.id) !== ""
                            ? getSelectionValue(service.id)
                            : false
                        }
                      />
                    )}{" "}
                    {service.base_price.currency_icon}
                    {service.base_price.amount}/
                    {service.charge_type === "amount" ? "One Time" : "per day"}
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : null}
        <br />
        <div className="actions flex justify-end w-full mt-4">
          <button
            className="text-white bg-neutral-500 py-1 px-2 text-md mr-2"
            onClick={() => navigate("/cars")}
          >
            Back
          </button>
          {!props.isBlocked ? (
            <button
              className="text-white bg-red-600 py-1 px-2 text-md"
              onClick={cartContinue}
            >
              Continue
            </button>
          ) : null}
        </div>
      </div>
      <RentalSummary
        step="billing"
        car={props.car}
        selectedAdditionalCharges={listSelectedAdditionalCharges}
        isBlocked={props.isBlocked}
        initCarInformation={props.initCarInformation}
      />
    </div>
  );
};

export default AdditionalCharges;
