import { useEffect, useState, useContext } from "react";
import RentalSummary from "../layout/blocks/RentalSummary";
import { Link, useNavigate } from "react-router-dom";
//import { PayPalButton } from "react-paypal-button-v2";
import {
  CartContext,
  AdditionalChargesContext,
  FilterContext,
} from "../context/MainProviders.js";
import Breadcrumb from "../layout/blocks/Breadcrumb";
import paymentApi from "../../service/paymentApi";
import api from "../../service/api";

const Placeorder = () => {
  const cart = useContext(CartContext);
  const [idCheckout, setIdCheckout] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [checkoutError, setCheckoutError] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [billingAddress, setBillingAddress] = useState(
    sessionStorage.getItem("billing_address") !== null
      ? JSON.parse(sessionStorage.getItem("billing_address"))
      : {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          city: "",
          address: "",
          zip_code: "",
          country: "MU",
          birthday: "",
          hotel_room: "",
        }
  );
  const closeFieldset = (id) => {
    let fieldset = document.getElementById(id);
    if (fieldset.classList.contains("hidden") === false) {
      fieldset.classList.add("hidden");
    } else {
      fieldset.classList.remove("hidden");
    }
  };
  const savePaymentTransactions = (idCheckout, timestamp) => {
    const postData = new FormData();
    postData.append("id_checkout", idCheckout);
    postData.append("timestamp", timestamp);
    postData.append("id_reservation", cart.idReservation);
    api
      .post("checkout", postData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const processPayment = async () => {
    setCheckoutError(false);
    const params = new URLSearchParams();
    const amount = (Math.round(cart.total * 100) / 100).toFixed(2);
    params.append("entityId", process.env.REACT_APP_3DSECURE);
    params.append("amount", amount);
    params.append("merchantTransactionId", String(cart.idReservation));
    params.append("currency", "EUR");
    params.append("paymentType", "DB");
    paymentApi
      .post("/v1/checkouts", params)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //save the payment in the backoffice to track all of them
          savePaymentTransactions(res.data.id, res.data.timestamp);
          setIdCheckout(res.data.id);
          setCheckoutSuccess(true);
          printPaymentGateway(res.data.id);
        }
      })
      .catch((err) => {
        setCheckoutError(true);
      });
  };
  const printPaymentGateway = (idCheckout) => {
    const script = document.createElement("script");
    script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${idCheckout}`;
    const paymentForm = document.createElement("form");
    paymentForm.setAttribute("action", process.env.REACT_APP_PAYMENT_RESPONSE);
    paymentForm.classList.add("paymentWidgets");
    paymentForm.setAttribute("data-brands", "VISA MASTER AMEX");
    const paymentGateway = document.getElementById("payment-gateway");
    paymentGateway.appendChild(script);
    paymentGateway.appendChild(paymentForm);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (cart.isEmpty) {
      navigate("/cars");
    } else {
      cart.changeCartStep("placeorder");
      setTimeout(() => {
        const process_payment_btn = document.getElementById("process-payment");
        process_payment_btn.click();
      }, 200);
    }
  }, []);
  return (
    <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl p-4">
      <Breadcrumb />
      <h2 className="text-red-600 text-2xl text-center mb-4">
        <span className="text-black text-lg">Our</span> <br />
        Cars
      </h2>
      <div id="placeorder" className="flex flex-col lg:flex-row">
        <div className="mb-2 sm:mb-10 w-full lg:w-3/4 flex flex-wrap lg:pr-6">
          <fieldset className="w-full">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onClick={() => closeFieldset("fielset-content-select-payment")}
            ></legend>
            <div
              id="fielset-content-select-payment"
              className="w-full fieldset-content flex-wrap hidden"
            >
              <div className="field w-full flex flex-row mb-10">
                <button
                  id="process-payment"
                  className="h-8 w-44 text-xs bg-sky-600 text-white text-light py-2 px-2 rounded-sm flex justify-between"
                  onClick={processPayment}
                >
                  Process payment{" "}
                  <span className="text-xs bg-white rounded-md px-1 py-0 text-sky-600 text-center">
                    {process.env.REACT_APP_CURRENCY}
                    {cart.total}
                  </span>
                </button>
              </div>
            </div>
            {checkoutError && (
              <div className="text-red-200 border-2 border-red-700 p-2 rounded-sm mt-2">
                There was an error processing the checkout, please try again, if
                the problem persists contact the administrator
              </div>
            )}
            {checkoutSuccess && <div id="payment-gateway"></div>}
          </fieldset>
          <div className="flex justify-end w-full">
            <Link
              className="text-sm bg-neutral-500 text-white py-2 px-3 h-[2.3rem] float-right inline-block"
              to={"/review"}
            >
              Back
            </Link>
          </div>
        </div>
        <RentalSummary step="final" />
      </div>
    </section>
  );
};

export default Placeorder;
