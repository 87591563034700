import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_CORS_PROXY,
  headers: {
    accept: "application/json",
    authorization: process.env.REACT_APP_API_AUTHORIZATION,
    "Target-URL": process.env.REACT_APP_API_DOMAIN,
  },
});
